<script lang="ts" setup>
defineProps<{
  claimId?: string;
  internal?: boolean | null;
  size?: "xs" | "sm" | "default";
  class?: string;
}>();
const { mutate, isPending } = useClaimInternalUpdate();
const hasAccess = useHasAccess();
</script>
<template>
  <Button
    v-if="hasAccess.editor"
    :class="$props.class"
    :size="size || 'sm'"
    :variant="!internal ? 'markedPublic' : 'white'"
    :icon="ICONS.community"
    :tooltip="internal ? 'claim.makePublic' : 'claim.makeInternal'"
    :loading="isPending"
    @click.prevent.stop="() => mutate({ id: claimId, internal: !internal })"
  />
</template>
