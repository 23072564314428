import {
  type UpdateUserClaimStatusByPkHasMarkedMutationVariables,
  type GetClaimByIdQuery,
  GqlUpdateUserClaimStatusByPkHasMarked
} from "#gql";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};
type Claim = GetClaimByIdQuery["claim"];
type MutationProps = Omit<UpdateUserClaimStatusByPkHasMarkedMutationVariables, "userId">;

export default function useUserClaimStatusHasMarkedUpdate(options?: Options) {
  const { userId } = useSessionInfo();
  const { onError } = useCacheOnErrorRevert();
  const queryClient = useQueryClient();
  const { onMutate } = useCacheOptimisticUpdate<MutationProps, NonNullable<Claim>>(updateDraft);
  const { onMutate: onMutateFilters } = useCacheInfinityOptimisticUpdate<MutationProps, NonNullable<Claim>>(
    "id",
    updateDraft
  );

  const context = useMutation({
    mutationKey: [MUTATION_KEYS.claimStatusUpdate],
    mutationFn: async (vars: MutationProps) => {
      return await GqlUpdateUserClaimStatusByPkHasMarked({
        claimId: vars.claimId,
        userId: userId.value,
        hasMarked: vars.hasMarked
      });
    },
    onMutate: async (vars) => {
      let mutationMap = await onMutate(vars, [
        [QUERY_KEYS.claim, vars.claimId],
        [QUERY_KEYS.submission, vars.claimId]
      ]);
      mutationMap = await onMutateFilters(
        vars,
        [[QUERY_KEYS.submissions], [QUERY_KEYS.claims]],
        mutationMap,
        vars.claimId
      );

      return { mutationMap };
    },
    onError,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims], exact: false });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.submissions], exact: false });
    }
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
function updateDraft(vars: MutationProps, draft: NonNullable<Claim>) {
  const hasRead = draft.userClaimStatuses?.[0]?.hasRead ?? false;
  draft.userClaimStatuses = [{ hasRead: hasRead, hasMarked: vars.hasMarked }];
}
